/* General page layout */
.contact-container {
    width: 100%;
    height: 100%;
    padding: 60px 20px;
    background-color: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Hero Section */
  .hero-section {
    width: 100%;
    padding: 80px 20px;
    background-color: #002f6c;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .hero-section h1 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.3;
    color: #000;
  }
  
  .hero-section p {
    font-size: 1.2rem;
    opacity: 0.8;
    line-height: 1.6;
  }
  
  /* About Section */
  .about-section {
    max-width: 800px;
    margin: 60px auto;
    text-align: center;
    padding: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .about-section h2 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
  }
  
  .about-section p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.8;
    margin: 0 auto;
    max-width: 700px;
  }
  
  /* Contact Info Section */
  .contact-info-section {
    padding: 40px 20px;
    background-color: #fff;
    width: 100%;
    max-width: 1200px;
    border-radius: 10px;
    margin-top: 60px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .contact-info-section h2 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 30px;
  }
  
  .contact-info-section p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  
  .contact-details {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 30px;
    font-size: 1.1rem;
    font-weight: 400;
    color: #555;
  }
  
  .contact-details div {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-details div:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .contact-details a {
    text-decoration: none;
    color: #0056b3;
    font-weight: 600;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .contact-details a:hover {
    color: #003f6b;
    transform: translateY(-3px);
  }
  
  .contact-details span {
    font-weight: 400;
    color: #777;
  }
  
  /* Images Section */
  .images-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
    max-width: 1200px;
  }
  
  .images-section img {
    width: 48%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .images-section img:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .images-section {
      flex-direction: column;
      gap: 20px;
    }
  
    .images-section img {
      width: 100%;
    }
  }
  