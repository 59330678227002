.search-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

/* Sidebar */
.filter-sidebar {
  width: 250px;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  height: fit-content;
  position: sticky;
  top: 10px;
}

.filter-sidebar h2 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
}

.apply-button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3s;
}

.apply-button:hover {
  background: #0056b3;
}

.apply-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

/* Product Display */
.product-display {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Product Card */
.product-card {
  width: 230px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background: white;
  transition: 0.3s;
}

.product-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Load More */
.load-more {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  padding: 10px;
}
