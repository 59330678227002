/* Trigger link style */
.support-link {
  font-size: 1.2rem;
  color: #0056b3;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
}

.support-link:hover {
  color: #003f6b;
}

/* Modal overlay style */
.support-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content style */
.support-modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.support-modal h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Input fields styling */
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.input-group textarea {
  height: 100px;
}

/* Optional label styling */
.optional {
  font-size: 0.9rem;
  color: #888; /* Light gray */
  font-style: italic;
}

/* Button group styling */
.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-group button:hover {
  background-color: #003f6b;
}

.close-btn {
  background-color: #f44336;
}

.close-btn:hover {
  background-color: #d32f2f;
}

/* Error and Success messages styling */
.error-message {
  color: #f44336; /* Red */
  font-size: 1rem;
  margin-bottom: 15px;
}

.success-message {
  color: #4caf50; /* Green */
  font-size: 1rem;
  margin-bottom: 15px;
}
