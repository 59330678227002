/* Ensure the body covers the entire screen and has a background image */
body {
  margin: 0;
  padding: 0;
  height: 100vh; /* Ensure the body covers full height */
  /* background: url('https://images.unsplash.com/photo-1504198458649-3128b932f49e?q=80&w=2840&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center fixed; */
  background-size: cover; /* Cover the entire page */
  font-family: 'Arial', sans-serif;
}

/* Main container for How it Works */
.how-it-works-container {
  background-color: rgba(255, 255, 255, 0.9); /* White with transparency for contrast */
  padding: 60px 20px;
  max-width: 1000px;
  margin: 0 auto;
  border-radius: 12px;
  text-align: center;
  z-index: 10;
}

/* Hero Section */
.hero-section {
  margin-bottom: 60px;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.25rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 40px;
}

/* Steps Section */
.steps-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
}

.step-card {
  background-color: #fff;
  border-radius: 10px;
  width: 250px;
  padding: 40px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.step-card:hover {
  transform: translateY(-10px); /* Subtle hover effect */
}

.icon {
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 20px;
}

.step-card h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.step-card p {
  font-size: 1rem;
  color: #555;
}

/* CTA Section */
.cta-section {
  margin-top: 40px;
}

.cta-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #ff5722;
  border: none;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e64a19; /* Darker orange for hover effect */
}
